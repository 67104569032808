@tailwind base;
@tailwind components;
@tailwind utilities;

html,
button,
input,
select,
textarea,
h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-sans;
}

body {
  @apply relative;
}

html,
body {
  @apply h-screen w-screen;
}

p {
  line-height: 150%;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@layer base {
  html {
    font-size: 16px;
  }
  :root {
    --background: 210 25% 98.43%;
    --foreground: 240 9.37% 12.55%;

    --muted: 214.29 30.43% 95.49%;
    --muted-foreground: 242.73 8.8% 50.98%;

    --popover: 324 0% 100%;
    --popover-foreground: 240 9.37% 12.55%;

    --card: 0 0% 100%;
    --card-foreground: 324 67% 0%;

    --border: 217.5 33.33% 90.59%;
    --input: 217.5 33.33% 90.59%;

    --primary: 226.15 26.53% 9.61%;
    --primary-foreground: 210 25% 98.43%;

    --secondary: 210 25% 98.43%;
    --secondary-foreground: 240 9.37% 12.55%;

    --accent: 218.18 44% 95.1%;
    --accent-foreground: 240 9.37% 12.55%;

    --destructive: 349.15 100% 60.98%;
    --destructive-foreground: 0 0% 100%;

    --ring: 258.82 60% 50%;
    --radius: 0.625rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
